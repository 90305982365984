import useFetch from '~api/useFetch';
import {IdmApiRoutes} from '~consts/routes';

export default function useFetchNonceOld() {
    const {apiGet} = useFetch();

    return (wallet: string) =>
        apiGet(IdmApiRoutes.Nonce, {
            queryParams: {
                walletAddress: encodeURIComponent(wallet),
            },
            baseUrl: process.env.NEXT_PUBLIC_API_ADDRESS,
        });
}
