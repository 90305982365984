import useFetch from '~api/useFetch';
import {IdmApiRoutes} from '~consts/routes';

export default function useFetchVerifySign() {
    const {apiGet} = useFetch();

    return (token: string, code: string) =>
        apiGet(IdmApiRoutes.GameVerify, {
            baseUrl: process.env.NEXT_PUBLIC_API_ADDRESS,
            queryParams: {code: encodeURIComponent(code)},
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
}
