import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useEthers} from '@usedapp/core';
import {useRouter} from 'next/router';
import {toUtf8Bytes} from 'ethers/lib/utils';
import {useScopeTranslation} from '@everdome_io/next-js-helper';
import {FallbackProvider} from '@ethersproject/providers';

import useFetchNonce from '~api/hooks/useFetchNonceOld';
import useFetchVerifySign from '~api/hooks/useFetchVerifySign';
import useFetchGameVerify from '~api/hooks/useFetchGameVerify';
import {showError} from '~utils/toast';
import ConnectWalletModal from '~components/download/molecules/ConnectWalletModal';
import {ApiErrorType} from '~types/api';
import GameLayout from '~components/download/organisms/GameLayout';
import {useJwtToken} from '~components/providers/AuthProvider';
import useFetchMe from '~api/useFetchMe';
import ChangeNameForm from '~components/download/molecules/ChangeNameForm';

import {Description, Skip, StyledButton} from './styles';

enum Steps {
    ConnectWallet = 0,
    Login = 1,
    SetUsername = 2,
    Welcome = 3,
}

const Login = () => {
    const [step, setStep] = useState(Steps.ConnectWallet);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nonce, setNonce] = useState();
    const [openModal, setOpenModal] = useState(false);

    const {account, error, library} = useEthers();
    const getNonce = useFetchNonce();
    const {setToken} = useJwtToken();
    const t = useScopeTranslation('game-download', 'login');
    const {data, refetch} = useFetchMe(success);
    const fetchVerifySign = useFetchVerifySign();
    const fetchGameVerify = useFetchGameVerify();
    const router = useRouter();

    const code = useMemo(() => {
        const strCode = router?.query?.code;
        return strCode && typeof strCode === 'string' ? strCode : undefined;
    }, [router]);

    const fetchNonce = useCallback(async (wallet: string) => {
        try {
            const data = await getNonce(wallet);
            setNonce(data.message);
        } catch (error_) {
            showError((error_ as ApiErrorType).message);
            setLoading(false);
        }
    }, []);

    const verifySign = async (signature: string) => {
        const body = JSON.stringify({
            walletAddress: account,
            signature,
        });
        try {
            setLoading(true);
            const response = await fetchVerifySign(body);
            const {token} = response;
            setToken(response);
            if (code) {
                const data = await fetchGameVerify(token, code);
                if (data) {
                    setSuccess(true);
                    setLoading(false);
                }
            }
        } catch (error_) {
            showError((error_ as ApiErrorType).message);
            setLoading(false);
            setSuccess(false);
        }
    };
    const signIn = async () => {
        if (!library || !nonce) {
            setLoading(false);
            showError('Please refresh page');
            return;
        }
        if (!(library instanceof FallbackProvider)) {
            const signer = library.getSigner();

            setLoading(true);
            try {
                const signature = await signer.signMessage(toUtf8Bytes(nonce));
                await verifySign(signature);
                setLoading(false);
            } catch {
                setLoading(false);
                showError((error as Error)?.message);
            }
        }
    };

    const renderStep = useCallback(() => {
        switch (step) {
            case Steps.Welcome:
                return (
                    <Description>
                        <p>{t('description1')}</p>
                        <p>{t('description2')}</p>
                    </Description>
                );
            case Steps.SetUsername:
                return (
                    <>
                        <ChangeNameForm
                            onSuccess={() => {
                                refetch();
                                setStep(Steps.Welcome);
                            }}
                        />
                        <Skip onClick={() => setStep(Steps.Welcome)}>
                            {t('skip')}
                        </Skip>
                    </>
                );
            case Steps.Login:
                return (
                    <StyledButton
                        variant="contained"
                        onClick={signIn}
                        disabled={loading}
                        loading={loading}
                    >
                        {t('login')}
                    </StyledButton>
                );
            default:
                return (
                    <StyledButton
                        variant="contained"
                        onClick={() => setOpenModal(true)}
                        loading={loading}
                    >
                        {t('connectWallet')}
                    </StyledButton>
                );
        }
    }, [step, loading, refetch]);

    const title = useMemo(() => {
        if (loading && Boolean(nonce)) {
            return t('continueInWallet');
        }
        switch (step) {
            case Steps.SetUsername:
                return t('setUsername');
            case Steps.Welcome:
                return t('niceToMeetYou', {username: data?.username || ''});
            default:
                return t('loginToContinue');
        }
    }, [step, loading, nonce, data]);

    useEffect(() => {
        if (error && (error as any)?.error?.error?.code != -32_000) {
            showError(error.message);
        }
    }, [error]);

    useEffect(() => {
        if (router.isReady && !code) {
            router.push('/404');
        }
    }, [code, router]);

    useEffect(() => {
        if (!account) {
            setStep(Steps.ConnectWallet);
            return;
        }
        if (!success || !data) {
            setStep(Steps.Login);
            return;
        }
        if (data?.username !== undefined) {
            setStep(Steps.Welcome);
        } else {
            setStep(Steps.SetUsername);
        }
    }, [account, success, data]);

    useEffect(() => {
        if (account) {
            setLoading(true);
            fetchNonce(account);
        }
    }, [account, fetchNonce]);

    useEffect(() => {
        if (nonce) {
            setLoading(false);
        }
    }, [nonce]);

    useEffect(() => {
        if (success) {
            setLoading(false);
            setStep(Steps.SetUsername);
        }
    }, [success]);

    useEffect(() => {
        if (account) {
            setOpenModal(false);
        }
    }, [account]);

    return (
        <GameLayout
            title={title}
            contentLine={step !== Steps.SetUsername && step !== Steps.Welcome}
            titleLine={step === Steps.SetUsername || step === Steps.Welcome}
        >
            {renderStep()}
            <ConnectWalletModal
                isOpen={openModal}
                close={() => setOpenModal(false)}
            />
        </GameLayout>
    );
};

export default Login;
