import useFetch from '~api/useFetch';
import {IdmApiRoutes} from '~consts/routes';

export default function useFetchVerifySign() {
    const {apiPost} = useFetch();

    return (body: any) =>
        apiPost(IdmApiRoutes.Login, {
            baseUrl: process.env.NEXT_PUBLIC_API_ADDRESS,
            body,
        });
}
