import React from 'react';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import Layout from '~components/download/organisms/Layout';
import LoginPage from '~components/download/organisms/Login';

const Login = () => {
    return (
        <Layout className={'loginPage'}>
            <LoginPage />
        </Layout>
    );
};

export default Login;

export const getStaticProps = async () => {
    const translations = await serverSideTranslations('en', [
        'game-download',
        'common-download',
    ]);
    return {
        props: {
            ...translations,
        },
    };
};
