import styled from 'styled-components';

import Button from '~components/download/atoms/Button';

type StyledButtonProps = {
    loading?: boolean;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
    width: 280px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
`;

export const Skip = styled.div`
    margin-top: 34px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
`;
export const Description = styled.div`
    text-align: center;
    font-size: 16px;
`;
